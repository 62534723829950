let config = {
    s3: {
        REGION: "us-east-2",
        BUCKET: "forwood-vale-testing-forwoodid",
        BATCH_PROCESS_BUCKET_NAME: "forwood-vale-testing-forwoodid-batch-user-process"
    },
    appSyncAPI: {
        REGION: "us-east-2",
        URL: "https://z73k6zuc2nh5lcxuczjgbowlga.appsync-api.us-east-2.amazonaws.com/graphql",
    },
    apiGateway: {
        REGION: "us-east-2",
        URL: "https://vmda7i17hh.execute-api.us-east-2.amazonaws.com/env",
        USER_TOKEN_URL: "https://7nkt8rum26.execute-api.us-east-2.amazonaws.com/prod"
    },
    configurationService: {
        URL: "https://config.testing.vale.forwoodsafety.com",
        WEBSOCKET: "wss://mwauy6oed8.execute-api.us-east-2.amazonaws.com/testing"
    },
    cognito: {
        REGION: "us-east-2",
        USER_POOL_ID: "us-east-2_HvAtul4tj",
        IDENTITY_POOL_ID: "us-east-2:593b0d99-2d50-48ee-ad55-97d6ff6f3a14",
        USERPOOL_HOSTED_DOMAIN: "forwood-vale-id-testing",
        USERPOOL_ADFS_CALLBACK_URL: "https://id.testing.vale.forwoodsafety.com",
        USERPOOL_ADFS_LOGOUT_URL: "https://id.testing.vale.forwoodsafety.com",
        AUTHENTICATED_ROLE_ARN: "arn:aws:iam::238161341106:role/testing-CA"
    },
    samlProvider: {
      NAME: 'ValeTesting',
      SUPPORTED_LIST: 'ValeTesting,ForwoodAzure'
    },
    reactApp: {
        VERSION: "1.35.6",
        HOSTNAME: "id.testing.vale.forwoodsafety.com",
        ENV_NAME: "testing",
        COOKIE_DOMAIN: ".testing.vale.forwoodsafety.com",
        SECURE_PROTOCOL: true,
        FORWOOD_COMPANY_ID: "45563fb2-5bf6-48e4-beae-ebb6547700b9",
        FORWOOD_HOMEPAGE_MICROFRONTEND_URL: "https://homepage.testing.vale.forwoodsafety.com"
    },
    USERPOOL_ADFS_LAUNCH_URL(clientId) {
        return 'https://'+this.cognito.USERPOOL_HOSTED_DOMAIN+'.auth.'+this.cognito.REGION+'.amazoncognito.com/authorize?redirect_uri='+this.cognito.USERPOOL_ADFS_CALLBACK_URL+'&response_type=code&client_id='+clientId;
    },
};

export default config;
